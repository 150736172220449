import axios, { AxiosResponse } from 'axios';
import { userApi } from 'constants/api';
import { ChangeUserPhotoStatusParams, GetUsersWithNewPhotosParams } from './types';

export class StudentsPhotosService {
  getUsersWithNewPhotos = async ({ showAll = false, page = 1, perPage = 50 }: GetUsersWithNewPhotosParams): Promise<AxiosResponse<any>> => axios.get(
    `${userApi}/users_with_new_foto`, {
      params: {
        ...(showAll && { show_all: true }),
        page,
        perPage
      }
    }
  );
  
  changeUserPhotoStatus = async ({ photoStatus, userId, comment }: ChangeUserPhotoStatusParams): Promise<AxiosResponse<any>> => axios.put(
    `${userApi}/changeFotoStatus`, null, {
      params: {
        foto_status: photoStatus,
        userId,
        ...(comment && { foto_comment: comment })
      }
    }
  );
  
}
