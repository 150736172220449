import { AxiosResponse } from 'axios';
import { Status } from 'constants/status';
import { makeAutoObservable } from 'mobx';
import { StudentsPhotosService } from 'store/apiClients/studentsPhotos';
import { Pagination } from 'store/utilityClasses/Pagination';
import { UserWithPhoto } from './types';
import {ChangeUserPhotoStatusParams, GetUsersWithNewPhotosParams} from '../../apiClients/studentsPhotos/types';

export class StudentsPhotosState {
  status = Status.Initial;
  students: UserWithPhoto[] = [];
  pagination: Pagination;
  showAll: boolean = false;
  
  readonly studentsPhotosService = new StudentsPhotosService();
  
  get isLoading(): boolean {
	return this.status === Status.Pending;
  }
  
  constructor() {
	makeAutoObservable(this, {}, { autoBind: true });
	this.pagination = new Pagination(this.getUsersWithNewPhotos);
  }
  
  * getUsersWithNewPhotos(): Generator {
    try {
	  this.status = Status.Pending;
	  const response = (yield this.studentsPhotosService.getUsersWithNewPhotos({
		showAll: this.showAll,
		page: this.pagination.page
	  })) as AxiosResponse<UserWithPhoto[]>;
	  this.students = response.data;
	  this.pagination.setPages(response.headers);
	  this.status = Status.Fulfilled;
    } catch (err) {
	  this.status = Status.Rejected;
      console.error(err);
    }
  }
  
  * changePhotoStatus(params: ChangeUserPhotoStatusParams): Generator {
    try {
	  yield this.studentsPhotosService.changeUserPhotoStatus(params);
	  this.getUsersWithNewPhotos();
    } catch (err) {
      console.error(err);
    }
  }
  
  setAllStudentsFlag(value: boolean): void {
    this.showAll = value;
  }
}
